import React, {useEffect, useState} from 'react';
import {ThemeProvider, Colors} from '@styles';
import ContactCallout, {
  ContactCalloutProps,
} from '@components/SectionCallout/ContactCallout';
import {StyledSection} from '@components/sections';
import UniversityPartnerFilter, {
  useFilterState,
} from '@components/UniversityPartnerFilter';
import {UniversityPartnerFilterProps} from '@components/UniversityPartnerFilter/Filters';
import {ProgramList} from './ProgramList';
import usePartnerPrograms, {ProgramProps} from '@util/usePartnerPrograms';
import alphaSort from '@util/alphaSort';
import {Option} from '@components/Form/Dropdown';
import {useContainerSize} from '@components/layout';

interface ProgramsOfferedProps extends ContactCalloutProps {
  programs: ProgramProps[];
}
interface ProgramGroupMap {
  [offeringType: string]: ProgramProps[];
}

function getOrCreateGroup(
  name: string,
  groupMap: ProgramGroupMap,
): ProgramProps[] {
  return groupMap[name] || (groupMap[name] = []);
}

function groupBy(
  programs: ProgramProps[],
  getOffering: (v: string) => Option,
): ProgramGroupMap {
  let groupMap = {};
  programs.forEach(program => {
    const {value} = getOffering(program.offering);
    const g = getOrCreateGroup(value, groupMap);
    g.push(program);
  });
  return groupMap;
}

function getFilterOptions(
  programs: ProgramProps[],
  getAcademicDiscipline: (v: string) => Option,
  getOffering: (v: string) => Option,
): UniversityPartnerFilterProps {
  let offerings = new Set<Option>();
  let academicDisciplines = new Set<Option>();
  programs.forEach(({offering, academicDiscipline}) => {
    offerings.add(getOffering(offering));
    academicDisciplines.add(getAcademicDiscipline(academicDiscipline));
  });
  return {
    academicDisciplineOptions: Array.from(
      academicDisciplines,
    ).sort((a: Option, b: Option) => alphaSort(a.text, b.text)),
    offeringTypeOptions: Array.from(offerings).sort((a: Option, b: Option) =>
      alphaSort(a.text, b.text),
    ),
  };
}

const ProgramsOffered = ({
  programs,
  headerBlock,
}: ProgramsOfferedProps): JSX.Element => {
  const {wide} = useContainerSize();
  const {getOffering, getAcademicDiscipline} = usePartnerPrograms();
  const {filterOptions} = useFilterState();
  const [filteredPrograms, updateFilteredPrograms] = useState(programs);
  const offeringTypes = groupBy(filteredPrograms, getOffering);
  const {academicDisciplineOptions, offeringTypeOptions} = getFilterOptions(
    programs,
    getAcademicDiscipline,
    getOffering,
  );

  useEffect(() => {
    if (filterOptions.offeringType && filterOptions.academicDiscipline) {
      updateFilteredPrograms(
        programs.filter(
          ({offering, academicDiscipline}) =>
            getOffering(offering).text === filterOptions.offeringType &&
            getAcademicDiscipline(academicDiscipline).text ===
              filterOptions.academicDiscipline,
        ),
      );
    } else if (filterOptions.academicDiscipline) {
      updateFilteredPrograms(
        programs.filter(
          ({academicDiscipline}) =>
            getAcademicDiscipline(academicDiscipline).text ===
            filterOptions.academicDiscipline,
        ),
      );
    } else if (filterOptions.offeringType) {
      updateFilteredPrograms(
        programs.filter(
          ({offering}) =>
            getOffering(offering).text === filterOptions.offeringType,
        ),
      );
    } else updateFilteredPrograms(programs);
  }, [filterOptions, programs]);

  return (
    <ThemeProvider
      theme={{
        minPadding: '2em',
        maxPadding: '6em 2em',
        maxWidth: wide ? '80%' : '100%',
      }}
    >
      <StyledSection>
        <ThemeProvider theme={{maxWidth: '600px'}}>
          <ContactCallout headerBlock={headerBlock} />
        </ThemeProvider>
        <UniversityPartnerFilter
          academicDisciplineOptions={academicDisciplineOptions}
          offeringTypeOptions={offeringTypeOptions}
        />
        <ThemeProvider
          theme={{
            ctaIconFg: Colors.Blue,
            ctaIconBg: Colors.White,
            ctaHoverBg: Colors.Blue,
            ctaBg: Colors.White,
            ctaFg: Colors.Charcoal,
            ctaHoverFg: Colors.White,
          }}
        >
          <ProgramList offeringTypes={offeringTypes} />
        </ThemeProvider>
      </StyledSection>
    </ThemeProvider>
  );
};

export default ProgramsOffered;
