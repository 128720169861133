import React, {Fragment} from 'react';
import {ResponsiveContainer} from '@components/layout';
import {styled, Fonts} from '@styles';
import {Program, ProgramProps} from './Program';

interface ProgramListProps {
  offeringTypes: Record<string, ProgramProps[]>;
}

interface ListProps {
  offeringType: string;
  programs: ProgramProps[];
}

const HeaderContainer = styled.div.withConfig({
  componentId: 'programListHeaderContainer'
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5em;
  flex-direction: column;

  .narrow & {
    align-items: center;
    flex-direction: row;
  }
`;

const ItemContainer = styled.div.withConfig({
  componentId: 'programListItemContainer'
})`
  margin-bottom: 15px;
`;

const Title = styled.div.withConfig({
  componentId: 'programListTitle'
})`
  ${Fonts.ProximaNova.variants.Bold};
  font-size: ${36 / 16}em;
  line-height: ${38 / 36};
  text-align: left;
  letter-spacing: ${-0.39 / 36}em;
  margin: 0;

  .narrow & {
    font-size: ${34 / 16}em;
    line-height: ${42 / 34};
    letter-spacing: ${-0.88 / 34}em;
  }
`;

const ListContainer = styled.div.withConfig({
  componentId: 'programListListContainer'
})`
  margin-bottom: 60px;

  .wide & {
    margin-bottom: 80px;
  }
`;

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'programListContainer'
})`
  margin-top: 2em;

  .wide & {
    margin-top: 4em;
  }
`;

const List = ({programs}: {programs: ProgramProps[]}): JSX.Element => {
  return (
    <Fragment>
      {programs.map(
        ({
          id,
          name,
          link,
          logo,
          academicDiscipline,
        }: ProgramProps): JSX.Element => (
          <ItemContainer key={id}>
            <Program
              id={id}
              name={name}
              link={link}
              discipline={academicDiscipline}
              schoolLogo={logo.url}
            />
          </ItemContainer>
        ),
      )}
    </Fragment>
  );
};

const RenderList = ({
  offeringType,
  programs,
}: ListProps): JSX.Element | null => {
  if (programs === undefined) return null;
  if (programs && programs.length <= 0) return null;
  return (
    <ListContainer key={offeringType}>
      <HeaderContainer>
        <Title>{offeringType}</Title>
      </HeaderContainer>
      <List programs={programs} />
    </ListContainer>
  );
};

export const ProgramList = ({
  offeringTypes,
}: ProgramListProps): JSX.Element | null => (
  <Container>
    {Object.entries(offeringTypes).map(
      ([offeringType, programs]): JSX.Element => (
        <RenderList
          key={offeringType}
          offeringType={offeringType}
          programs={programs}
        />
      ),
    )}
  </Container>
);
