import React from 'react';
import {useSpring, animated, UseSpringProps} from 'react-spring';
import useHover from '@hzdg/use-hover';
import CircleArrowIcon from '@components/icons/CircleArrow';
import {ResponsiveContainer} from '@components/layout';
import {Link} from '@components/Link';
import {styled, useTheme, Fonts, Colors} from '@styles';
import usePartnerPrograms from '@util/usePartnerPrograms';

export interface ProgramProps {
  name: string;
  schoolLogo: string;
  discipline: string;
  id: string;
  link: string;
}

const Title = styled.div.withConfig({
  componentId: 'programTitle'
})`
  ${Fonts.ProximaNova.variants.Bold};
  font-size: ${18 / 16}em;
  line-height: ${28 / 18};
  letter-spacing: ${-0.52 / 18}em;
  margin-bottom: 1em;
  flex: 1;

  .narrow & {
    font-size: ${24 / 16}em;
    line-height: ${26 / 24};
    letter-spacing: ${-0.62 / 24}em;
  }

  .wide & {
    margin: 0;
  }
`;

const SubTitle = styled.div.withConfig({
  componentId: 'programSubTitle'
})`
  ${Fonts.ProximaNova};
  font-size: ${16 / 16}em;
  line-height: ${24 / 16};
  letter-spacing: ${-0.43 / 16}em;
  margin-top: 1em;
  flex: 1;
  text-align: left;

  .narrow & {
    font-size: ${18 / 16}em;
    line-height: ${28 / 18};
    letter-spacing: ${-0.52 / 18}em;
  }

  .wide & {
    margin: 0;
    text-align: right;
  }
`;

const Container = styled(animated(ResponsiveContainer)).withConfig({
  componentId: 'programContainer'
})`
  display: flex;
  border: 2px solid #d0d0d0;
  border-radius: 8px;
  padding: 1rem;
  align-items: center;
  cursor: pointer;
  text-align: left;

  .narrow & {
    padding: 1rem 1.5rem;
  }
`;

const PostContainer = styled.div.withConfig({
  componentId: 'programPostContainer'
})`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  .wide & {
    align-items: center;
    flex-direction: row;
  }
`;

const ArrowContainer = styled.div.withConfig({
  componentId: 'programArrowContainer'
})`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const ImageContainer = styled.div.withConfig({
  componentId: 'programImageContainer'
})`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  .wide & {
    justify-content: center;
    padding-left: 10%;
  }
`;

const Image = styled.div.withConfig({
  componentId: 'programImage'
})<{image: string}>`
  width: 100%;
  min-height: 34px;
  max-width: 250px;
  background-image: ${({image}) => `url(${image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
`;

export const Program = ({
  id,
  link,
  name,
  discipline,
  schoolLogo,
}: ProgramProps): JSX.Element => {
  const theme = useTheme();
  const [isHovering, hoverProps] = useHover();
  const {getAcademicDiscipline} = usePartnerPrograms();
  const {value} = getAcademicDiscipline(discipline);
  const {
    color,
    boxShadow,
    arrowFill,
    backgroundFill,
    backgroundStroke,
  } = useSpring<UseSpringProps<any>>({
    to: {
      color: isHovering ? theme.ctaHoverBg : theme.ctaFg,
      boxShadow: isHovering
        ? `0 7px 10px -5px ${Colors.FogDark}`
        : `0px 0px 0px 0px ${Colors.Transparent}`,
      arrowFill: isHovering ? theme.ctaIconBg : theme.ctaIconFg,
      backgroundFill: isHovering ? theme.ctaIconFg : theme.ctaIconBg,
      backgroundStroke: theme.ctaHoverBg,
    },
  });
  return (
    <Link
      href={link}
      style={{textTransform: 'none'}}
      aria-labelledby={id}
      target="_blank"
    >
      <Container
        style={{
          color,
          boxShadow,
        }}
        {...hoverProps}
      >
        <PostContainer>
          <Title id={id}>{name}</Title>
          <ImageContainer>
            <Image image={schoolLogo} aria-hidden />
          </ImageContainer>
          <SubTitle aria-hidden>{value}</SubTitle>
        </PostContainer>
        <ArrowContainer>
          <CircleArrowIcon
            arrowFill={arrowFill}
            backgroundFill={backgroundFill}
            backgroundStroke={backgroundStroke}
          />
        </ArrowContainer>
      </Container>
    </Link>
  );
};
