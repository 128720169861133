import React from 'react';

import Image from '@components/Image';
import {AmbientVideo} from '@components/Video';
import {useContainerSize} from '@components/layout';
import {styled} from '@styles';
import {
  ImageColumnContainer,
  GenericImageGrid,
  ResponsiveContainerProps,
} from './OrganicImageGrid';
import {MediaGridBlock} from './types';

const TwoColumnImageGrid = styled(GenericImageGrid).withConfig({
  componentId: 'tiogTwoColumnImageGrid'
})<ResponsiveContainerProps>`
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin: 0 auto;
  padding: 2em;
  max-width: 100%;

  .wide & {
    padding: 0;
    max-width: 80%;
  }
`;

const StyledVideo = styled(AmbientVideo).withConfig({
  componentId: 'tiogVideo'
})`
  .wide & {
    transform: translateY(3em);
  }
`;

export default function TwoImagesOrganicGrid({
  imageBlocks,
  videoBlocks,
}: MediaGridBlock): JSX.Element | null {
  if (!imageBlocks && !videoBlocks) return null;
  const {wide} = useContainerSize();

  let firstVideo;
  let firstImage;
  let secondImage;

  if (videoBlocks) firstVideo = videoBlocks[0];
  if (imageBlocks) {
    firstImage = imageBlocks[0];
    secondImage = imageBlocks[1];
  }

  return (
    <TwoColumnImageGrid wide={wide}>
      {firstImage && firstImage.image && (
        <ImageColumnContainer wide={wide} justify="flex-start">
          <Image
            childImageSharp={firstImage.image.childImageSharp}
            style={{
              // maxWidth:
              //   firstImage.image.childImageSharp.fluid.presentationWidth,
              margin: '0',
              width: '100%',
              height: wide ? '80%' : '100%',
            }}
            preservePresentationWidth={false}
            alt={firstImage.caption}
            {...firstImage.image}
          />
        </ImageColumnContainer>
      )}
      <ImageColumnContainer wide={wide} align="flex-start" justify="flex-end">
        {secondImage && secondImage.image && !firstVideo && (
          <Image
            childImageSharp={secondImage.image.childImageSharp}
            style={{
              // maxWidth:
              //   secondImage.image.childImageSharp.fluid.presentationWidth,
              margin: wide ? '0' : '0 10px 0 0',
              width: '100%',
              height: wide ? '80%' : '100%',
            }}
            preservePresentationWidth={false}
            alt={secondImage.caption}
            {...secondImage.image}
          />
        )}
        {firstVideo && firstVideo.video && !secondImage && (
          <StyledVideo
            videoSrc={firstVideo.video.url}
            placeholderImage={firstVideo.image}
          />
        )}
      </ImageColumnContainer>
    </TwoColumnImageGrid>
  );
}
